import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src="https://static.wixstatic.com/media/04d1f5_2f5951c18cec4aafbc673aa4e5a2f193~mv2.gif"
          alt="logo"
        />

        <a
          className="App-link"
          href="https://www.facebook.com/KYBPROJECTS"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit our FB Page
        </a>
      </header>
    </div>
  );
}

export default App;
